<template>
  <section class="app-ecommerce-details">

    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="product === undefined"
    >
      <h4 class="alert-heading">
        Не удалось получить данные о товаре.
      </h4>
      <div class="alert-body">
        Ничего не найдено с этим идентификатором.
        <b-link
          class="alert-link"
          :to="{ name: 'shop'}"
        >
          Вернуться в список товаров.
        </b-link>
      </div>
    </b-alert>

    <!-- Content -->
    <b-card
      v-if="product"
      no-body
    >
      <b-card-body>
        <b-row class="my-2">

          <!-- Left: Product Image Container -->
          <b-col
            v-if="!product.slider_images || product.slider_images.length === 1"
            cols="12"
            md="5"
            class="d-flex align-items-start justify-content-center mb-2 mb-md-0"
          >
            <div class="d-flex align-items-center justify-content-center">
              <b-img
                :src="server + product.image"
                :alt="product.name"
                class="product-img"
                fluid
              />
            </div>
          </b-col>
          <b-col
            v-else
            cols="12"
            md="5"
            class="d-flex align-items-start justify-content-center mb-2 mb-md-0"
          >
            <div class="d-flex align-items-center justify-content-center">
              <b-carousel
                id="carousel-fade"
                style="text-shadow: 0px 0px 2px #000"
                fade
                indicators
                :interval="3000"
              >
                <b-carousel-slide
                  v-for="(src, key) in product.slider_images"
                  :key="key"
                  :img-src="server + src"
                  class="d-flex"
                ></b-carousel-slide>
              </b-carousel>
            </div>
          </b-col>

          <!-- Right: Product Details -->
          <b-col
            cols="12"
            md="7"
          >

            <!-- Product Name -->
            <h4>{{ product.name }}</h4>

            <!-- Product Brand -->
            <b-card-text class="item-company mb-0">
              <span>от</span>
              <b-link class="company-name">
                {{ product.brand }}
              </b-link>
            </b-card-text>

            <div :class="`text-${product.availableColor} mb-1`">
              {{ product.available }}
            </div>

            <!-- Price And Ratings -->
            <div class="ecommerce-details-price d-flex flex-wrap mt-1">
              <div v-if="product.prizn == '1'">
                <h4 class="item-price mr-1">
                  {{ product.price }} руб.
                </h4>
                <h4 class="text-secondary mr-1">
                  <s>{{ product.priceOld }} руб.</s>
                </h4>
                <h4 class="item-price mr-1">
                  Цена бал. {{ product.priceBall }}
                </h4>
              </div>
              <div v-else-if="product.prizn == '0'">
                <h4 class="item-price mr-1">
                  {{ product.priceOld }} руб.
                </h4>
                <div class="d-flex align-items-center">
                  <h4 class="text-secondary mr-50 mb-0">
                    {{ product.price }} руб.
                  </h4>
                  <b-button
                    v-b-tooltip.hover.v-primary
                    :title="product.priznComment"
                    variant="flat-primary"
                    class="btn-icon rounded-circle p-0 m-0"
                  >
                    <feather-icon icon="InfoIcon" />
                  </b-button>
                </div>
                <h4 class="item-price mr-1">
                  Цена бал. {{ product.priceBall }}
                </h4>
              </div>
              <div v-else>
                <h4 class="item-price mr-1">
                  {{ product.price }} руб.
                </h4>
                <h4 class="item-price mr-1">
                  Цена бал. {{ product.priceBall }}
                </h4>
              </div>
              <ul class="unstyled-list list-inline pl-1 border-left">
                <li
                  v-for="star in 5"
                  :key="star"
                  class="ratings-list-item mr-25"
                >
                  <feather-icon
                    icon="StarIcon"
                    size="18"
                    :class="[{'fill-current': star <= product.rating}, star <= product.rating ? 'text-warning' : 'text-muted']"
                  />
                </li>
              </ul>
            </div>

            <b-tabs>
              <b-tab title="Описание">
                <!-- Product Description -->
                <b-card-text>
                  <div v-html="product.description" />
                </b-card-text>
              </b-tab>
              <b-tab
                v-if="product.addescription"
                title="Доп. описание"
              >
                <b-card-text>
                  <div v-html="product.addescription" />
                </b-card-text>
              </b-tab>
              <b-tab title="Вес и Размеры">
                <ul>
                  <li>Вес, грамм: <strong>{{ product.Prod_Mass }}</strong></li>
                  <li>Длина, мм: <strong>{{ product.prod_length }}</strong></li>
                  <li>Ширина, мм: <strong>{{ product.prod_width }}</strong></li>
                  <li>Высота, мм: <strong>{{ product.prod_height }}</strong></li>
                </ul>
              </b-tab>
            </b-tabs>

            <div class="d-flex flex-column flex-sm-row pt-1">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="btn-cart mr-0 mr-sm-1 mb-1 mb-sm-0"
                @click="handleCartActionClick(product)"
              >
                <feather-icon
                  icon="ShoppingCartIcon"
                  class="mr-50"
                />
                <span>{{ product.isInCart !== '0' ? 'Посмотреть в корзине' : 'Добавить в корзину' }}</span>
              </b-button>
              <b-button
                variant="outline-secondary"
                class="btn-wishlist mr-0 mr-sm-1 mb-1 mb-sm-0"
                @click="toggleProductInWishlist(product)"
              >
                <feather-icon
                  icon="HeartIcon"
                  class="mr-50"
                  :class="{'text-danger': product.isInWishlist !== '0'}"
                />
                <span>Избранное</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </b-card-body>
      <!-- <e-commerce-product-details-item-features /> -->
      <e-commerce-product-details-related-products
        v-if="relatedProducts"
        :related-products="relatedProducts"
      />
    </b-card>
  </section>
</template>

<script>
import {
  BCard, BCardBody, BRow, BCol, BImg, BCardText, BLink, BButton, BAlert,
  VBTooltip, BTabs, BTab, BCarousel, BCarouselSlide,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { useRouter } from '@core/utils/utils'
import { ref, watch } from '@vue/composition-api'
import store from '@/store'
import { useEcommerceUi } from '../useEcommerce'
// import ECommerceProductDetailsItemFeatures from './ECommerceProductDetailsItemFeatures.vue'
import ECommerceProductDetailsRelatedProducts from './ECommerceProductDetailsRelatedProducts.vue'

export default {
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  components: {
    // BSV
    BCard,
    BCardBody,
    BRow,
    BCol,
    BImg,
    BCardText,
    BLink,
    BButton,
    BAlert,
    BTabs,
    BTab,
    BCarousel,
    BCarouselSlide,
    // ECommerceProductDetailsItemFeatures,
    ECommerceProductDetailsRelatedProducts,
  },
  setup() {
    const { handleCartActionClick, toggleProductInWishlist } = useEcommerceUi()

    const product = ref(null)
    const relatedProducts = ref(null)
    const { route } = useRouter()

    // Remote Data
    const fetchProduct = () => {
      const productId = route.value.params.id

      store.dispatch('app-ecommerce/fetchProduct', { productId })
        .then(response => {
          const { data } = response.data
          product.value = data
          route.value.meta.breadcrumb = [{ text: 'Магазин', active: false, to: '/shop' }, { text: data.name, active: true }]
        })
        .catch(error => {
          if (error.response.status === 404) {
            product.value = undefined
          }
        })
    }

    const fetchRelatedProducts = () => {
      // Get product  id from URL
      const productId = route.value.params.id

      store.dispatch('app-ecommerce/fetchRelatedProducts', productId)
        .then(response => {
          const { data } = response.data
          relatedProducts.value = data.rows
        })
        .catch(error => {
          if (error.response.status === 404) {
            relatedProducts.value = undefined
          }
        })
    }

    const server = process.env.VUE_APP_SERVER

    fetchProduct()
    fetchRelatedProducts()

    watch([route], () => {
      product.value = null
      relatedProducts.value = null
      fetchProduct()
      fetchRelatedProducts()
    })

    return {
      server,
      // Fetched Product
      product,
      relatedProducts,

      handleCartActionClick,
      toggleProductInWishlist,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce-details.scss";
</style>
